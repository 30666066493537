/**
 * Copyright Highway9 Networks Inc.
 **/
import React from "react";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../constants/routes";
import { useAppSelector } from "~/store/hooks";
import { Images } from "~/components/GraphicalResources";

export const DRAWER_WIDTH = 60;

type Props = {};

const styles = {
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    textAlign: "center",
    "& .MuiDrawer-paper": {
      background: "#226A58",
      width: DRAWER_WIDTH,
      position: "relative",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: 0,
    },
  },
  logo: {
    padding: "22px 0 24px",
  },
  listContainer: {
    padding: "8px 12px",
    "& a": {
      color: "#0000008a",
    },
  },
  listItem: {
    padding: "8px",
    width: "36px",
    height: "36px",
    marginX: "auto",
    marginBottom: "4px",
    borderRadius: "8px",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
      fill: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#FFFFFF26",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFFFFF33",
    },
  },
  divider: {
    borderColor: "#EFEFEF26",
    marginTop: "8px",
    marginBottom: "12px",
  },
  versionText: {
    fontSize: "10px",
    fontWeight: 500,
    color: "white",
    opacity: 0.7,
  },
} satisfies Record<string, SxProps<Theme>>;

const SideDrawer = (props: Props) => {
  const location = useLocation();
  const versionNumber = useAppSelector((state) => state.serviceInstance.summary?.release);
  const routesArray = routes()
  // const classes = useStyles();
  return (
    <Drawer variant="permanent" sx={styles.drawer}>
      <Grid height="100%" container flexDirection="column" justifyContent="space-between">
        <List sx={styles.listContainer}>
          <Grid sx={styles.logo}>
            <img src={Images.highway9Logo} alt="h9-logo" />
          </Grid>
          {routesArray.map((route, index) => {
            if (route.hidden) {
              return route.dividerAfter ? <Divider key={`divider-${index}`} sx={styles.divider} /> : null;
            }
            return (
            <>
              <Tooltip
                title={route.name}
                aria-label={route.name}
                placement="right"
                key={`tooltip-${route.path}-${index}`}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#292B2F",
                      marginLeft: "8px !important",
                      padding: "8px",
                      fontSize: "12px",
                      lineHeight: "16px",
                      fontWeight: 600,
                      color: "#F7F7F7",
                    },
                  },
                }}
                >
                <Link to={route.path ?? "#"}>
                  <ListItem
                    sx={styles.listItem}
                    selected={location.pathname === route.path}
                    key={`list-item-${route.path}-${index}`}
                    >
                    <ListItemIcon>{route.icon}</ListItemIcon>
                  </ListItem>
                </Link>
              </Tooltip>
              {route.dividerAfter && <Divider key={`divider-${index}`} sx={styles.divider} />}
            </>
          );
          })}
        </List>

        <div>
          <Divider sx={styles.divider} />
          <Box mt={"4px"} mb={"16px"}>
            <Typography variant="caption" sx={styles.versionText}>
              {versionNumber}
            </Typography>
          </Box>
        </div>
      </Grid>
    </Drawer>
  );
};

export default SideDrawer;
