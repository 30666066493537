import { jsx as e, Fragment as d, jsxs as i } from "react/jsx-runtime";
import { useState as h } from "react";
import { Grid as r, CircularProgress as F } from "@mui/material";
import { Button as v } from "../atoms/Button/Button.js";
import "../../map-1c5b8782.js";
import "../../server.browser-ec1ee1ca.js";
import "../atoms/Grid/Grid.js";
import { Input as x } from "../atoms/Input/Input.js";
import { Typography as n } from "../atoms/Typography/Typography.js";
import { asset as f } from "../../assets/icons/index.js";
import { d as B } from "../../ArrowForward-a2088ae0.js";
const q = ({ errorsComponent: l, loading: p, onLogin: y, onNext: m, onForgotPassword: c, onLicenseAgreement: a, hidePassword: u = !0 }) => {
  const [s, w] = h(""), [g, C] = h("");
  function b(o) {
    o == null || o.preventDefault(), u ? m == null || m(s) : y(s, g);
  }
  return /* @__PURE__ */ e(d, { children: /* @__PURE__ */ e(r, { container: !0, sx: t.main, children: /* @__PURE__ */ i(r, { sx: t.loginContainer, children: [
    /* @__PURE__ */ i(r, { sx: t.topContainer, children: [
      /* @__PURE__ */ e(r, { sx: t.logo, children: /* @__PURE__ */ e("img", { src: f.loginPageLogo, width: "236" }) }),
      /* @__PURE__ */ i(r, { component: "form", onSubmit: b, sx: t.loginForm, children: [
        /* @__PURE__ */ e(n, { sx: t.welcomeBackText, color: "#878787", children: "Welcome back," }),
        /* @__PURE__ */ e(n, { sx: t.loginTitleText, color: "#141414", children: "Log in to your account" }),
        /* @__PURE__ */ e(r, { sx: t.error, children: l }),
        /* @__PURE__ */ e(
          x,
          {
            label: "Email",
            name: "email",
            required: !0,
            id: "username",
            placeholder: "Email",
            sx: t.inputEmail,
            type: "email",
            value: s,
            onChange: (o) => w(o.target.value),
            autoFocus: !0
          }
        ),
        !u && /* @__PURE__ */ e(
          x,
          {
            label: "Password",
            id: "password",
            name: "password",
            required: !0,
            type: "password",
            placeholder: "Password",
            autoFocus: !0,
            sx: t.inputPass,
            value: g,
            onChange: (o) => C(o.target.value)
          }
        ),
        /* @__PURE__ */ e(v, { type: "submit", sx: t.loginButton, disabled: p, children: p ? /* @__PURE__ */ i(d, { children: [
          p && /* @__PURE__ */ e(F, { size: 24 }),
          "  "
        ] }) : /* @__PURE__ */ i(d, { children: [
          /* @__PURE__ */ e(n, { type: "heading3", color: "#FFFFFF", sx: t.loginText, children: "Log In" }),
          /* @__PURE__ */ e(B, {})
        ] }) }),
        /* @__PURE__ */ i("div", { style: t.actions, children: [
          /* @__PURE__ */ e("div", { onClick: () => c == null ? void 0 : c(), children: /* @__PURE__ */ e(n, { type: "heading3", color: (o) => o.palette.primary.main, sx: { ...t.pointer, fontWeight: 500 }, children: "Forgot/Reset Password" }) }),
          !!a && /* @__PURE__ */ e("div", { onClick: () => a == null ? void 0 : a(), children: /* @__PURE__ */ e(n, { type: "heading3", color: (o) => o.palette.primary.main, sx: { ...t.pointer, fontWeight: 500 }, children: "Lisence Agreement" }) })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ i(r, { sx: t.noAccount, children: [
      /* @__PURE__ */ e(n, { type: "heading4", color: "#141414", sx: { fontWeight: 400 }, children: "Don’t have an account?" }),
      /* @__PURE__ */ e("div", { onClick: () => {
        window.open("mailto:support@highway9.com");
      }, children: /* @__PURE__ */ e(n, { type: "heading3", color: (o) => o.palette.primary.main, sx: t.pointer, children: "Contact Support" }) })
    ] })
  ] }) }) });
}, t = {
  main: (l) => ({
    width: "100vw",
    height: "100vh",
    overflow: "none",
    backgroundColor: l.palette.primary.main,
    backgroundImage: `url(${f.loginPageBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }),
  logo: {
    textAlign: "center",
    marginBottom: "40px"
  },
  loginContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "24px",
    width: "100%",
    mx: "8px",
    maxHeight: "95vh",
    maxWidth: "500px"
  },
  topContainer: {
    padding: "3rem 3.5rem 2rem"
  },
  loginForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    gap: "8px"
  },
  noAccount: {
    borderTop: "1px solid #EFEFEF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: "32px",
    gap: "8px"
  },
  pointer: {
    cursor: "pointer"
  },
  loginButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    width: "100%",
    marginBottom: "16px",
    "&::before ": {
      content: "''",
      width: 0
    }
  },
  welcomeBackText: {
    fontSize: "1rem",
    marginBottom: "8px"
  },
  loginTitleText: {
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "8px"
  },
  loginText: {
    textAlign: "center"
  },
  inputEmail: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  inputPass: {
    marginTop: "12px",
    marginBottom: "24px"
  },
  error: {
    marginBottom: "1rem",
    marginTop: "0.5rem",
    width: "100%",
    "& .MuiAlert-root": {
      borderRadius: "8px"
    }
  }
};
export {
  q as default
};
