import React, { useEffect } from 'react'
import AlertDialog from '../AlertDialog';
import moment from 'moment-timezone';
import { Icons } from '../GraphicalResources';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { serviceInstanceActions } from '~/store/context/serviceInstanceSlice';

type Props = {}

const LoginExpiryAlert = (props: Props) => {

    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.utility.user);
    const { passwordExpiryTime } = user ?? { passwordExpiryTime: 0 };
    const [expiry, setExpiry] = React.useState(false);

    const expiryTime = passwordExpiryTime - moment().unix(); // in seconds
    const daysLeft = moment.duration(expiryTime, 'seconds').humanize();
    const expiryDate = moment.unix(passwordExpiryTime).format('MMM, Do YYYY');

    useEffect(() => {
        // if password is about to expire in 7 days
        const days7 = 7 * 24 * 60 * 60;
        if (expiryTime > 0 && expiryTime < days7) {
            setExpiry(true);
        }
    }, [expiryTime])

    return (
        <AlertDialog
            open={expiry}
            setOpen={setExpiry}
            title={<div style={{
                display: 'flex',
                alignItems: 'center'
            }}> {Icons.infoWarning} &nbsp; Password expiry due in {daysLeft}</div>}
            message={
                <>
                    Your password will expire in {daysLeft} on {expiryDate}.
                    <br />
                    Do you want to change it now?
                    <br />
                    <br />
                </>
            }
            confirmText='Change Now'
            cancelText='Later'
            onConfirm={() => {

                dispatch(serviceInstanceActions.setProfileOpen(true));
                setExpiry(false);
            }}
        />
    )
}

export default LoginExpiryAlert