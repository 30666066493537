import "./assets/style.css";
const o = "_value_gkokn_3", n = "_valueText_gkokn_42", _ = "_label_gkokn_47", e = "_multiChips_gkokn_57", t = "_chip_gkokn_64", k = "_menu_gkokn_80", l = "_options_gkokn_84", s = "_input_gkokn_101", i = "_error_gkokn_129", u = "_disabled_gkokn_133", c = "_group_gkokn_142", g = "_option_gkokn_84", p = "_selected_gkokn_166", a = "_icon_gkokn_170", d = {
  value: o,
  "value-outlined": "_value-outlined_gkokn_19",
  "value-filled": "_value-filled_gkokn_27",
  "value-none": "_value-none_gkokn_35",
  valueText: n,
  label: _,
  multiChips: e,
  chip: t,
  menu: k,
  options: l,
  input: s,
  error: i,
  disabled: u,
  group: c,
  option: g,
  selected: p,
  icon: a
};
export {
  d as s
};
